<template>
    <div class="row mt-3">
        <div class="col-md-3">
            {{name}}
        </div>
        <div class="col-md-9">
            {{value}}
        </div>
    </div>
</template>

<script>

    export default {
        name: 'RowTwoColumns',
        props: ['name', 'value'],
    }

</script>