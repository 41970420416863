<template>
  <!-- Modal -->
  <div class="modal fade" id="infoResearchWork" tabindex="-1"
       aria-labelledby="editResearchWorkModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Информация</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mt-4">
            <RowTwoColumns name="Вид научно-исследовательской работы" :value="research_work_id"/>
            <RowTwoColumns name="Подраздел научно-исследовательской работы" :value="sub_research_work"/>
            <RowTwoColumns name="Название работы" :value="articles[researchWorkEditIndex].book_name"/>
            <RowTwoColumns v-if="articles[researchWorkEditIndex].research_work_id==3" name="Название конференции"
                           :value="articles[researchWorkEditIndex].conference_name"/>
            <RowTwoColumns name="Язык работы" :value="languageName"/>
            <RowTwoColumns name="Соавторы" :value="articles[researchWorkEditIndex].co_autors"/>
            <RowTwoColumns name="Страна" :value="articles[researchWorkEditIndex].country"/>
            <RowTwoColumns name="Издательство" :value="articles[researchWorkEditIndex].publishing_house"/>
            <RowTwoColumns name="Год" :value="articles[researchWorkEditIndex].year"/>
            <RowTwoColumns name="Номер журнала" :value="articles[researchWorkEditIndex].eid"/>
            <RowTwoColumns name="Номер страницы" :value="articles[researchWorkEditIndex].page_number"/>
            <RowTwoColumns name="Инфо-фактор" :value="articles[researchWorkEditIndex].info_factor"/>
            <RowTwoColumns name="ISSN" :value="articles[researchWorkEditIndex].issn"/>
            <RowTwoColumns name="ISBN" :value="articles[researchWorkEditIndex].isbn"/>
            <RowTwoColumns name="DOI" :value="articles[researchWorkEditIndex].doi"/>

            <!--            <div class="row mt-3" v-if="articles[researchWorkEditIndex].link">-->
            <!--              <div class="col-md-3">-->
            <!--                URL-адрес-->
            <!--              </div>-->
            <!--              <div class="col-md-9">-->
            <!--                <a :href="articles[researchWorkEditIndex].link">Перейти</a>-->
            <!--              </div>-->
            <!--            </div>-->

            <RowTwoColumns name="Примечания" :value="articles[researchWorkEditIndex].note"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>

  import {mapGetters, mapState} from "vuex"
  import RowTwoColumns from "@/components/common/RowTwoColumns"


  export default {
    name: 'InfoResearchWorkModal',
    components: {RowTwoColumns},
    computed: {
      ...mapState('userinfo', ['articles']),
      ...mapState('researchwork', ['researchWorkType', 'subResearchWorkType', 'selectedResearchWorkType', 'researchWorkEditIndex']),
      ...mapGetters('languageskills', ["ALL_LANGUAGES"]),
      getSubResearchWorkType() {
        let subResearch = this.subResearchWorkType.filter(i => i.parent_id == this.articles[this.researchWorkEditIndex].research_work_id)
        return subResearch
      },
      research_work_id() {
        let research_work = this.researchWorkType.find(i => i.id == this.articles[this.researchWorkEditIndex].research_work_id)
        if (!research_work) {
          return ''
        }
        return research_work.name
      },
      sub_research_work() {
        let sub_research_work = this.getSubResearchWorkType.find(i => i.id == this.articles[this.researchWorkEditIndex].sub_research_work)
        if (!sub_research_work) {
          return ''
        }
        return sub_research_work.name
      },
      languageName() {
        let lang = this.ALL_LANGUAGES.find(i => i.id == this.articles[this.researchWorkEditIndex].language)
        if (!lang) {
          return ''
        }
        return lang.native_name
      },


    },
  }

</script>

<style scoped>
</style>