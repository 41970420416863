<template>
  <div class="language-skills-form mt-4">
    <h4 class="text-center">Знание языков</h4>

    <div v-if="langData.length" class="mt-4">

      <div v-for="(languageInfo, index) in langData" :key="index">
        <RowTwoColumns name="Язык" :value="getLanguageName(languageInfo.lang_id)"/>
        <RowTwoColumns name="Уровень"
                       :value="ALL_LANGUAGES_LEVEL.find(i=>i.id == languageInfo.lang_level_id).full_name"/>

        <div v-if="(languageInfo.certificate == 1)&&languageInfo.file">

          <RowTwoColumns name="Тип"
                         :value="languageCertificationTypes.find(item=>item.id == languageInfo.language_certification_type_id)?.name"/>

          <RowTwoColumns name="Количество баллов" :value="languageInfo.score"/>

          <RowTwoColumns name="Дата получения" :value="languageInfo.date_receive"/>

          <div class="row mt-3">
            <div class="col-md-3">Сертификат</div>
            <div class="col-md-9">
              <a :href="'https://back.uib.kz/uploads/' + languageInfo.file"> {{ languageInfo.file }} </a>
            </div>
          </div>

        </div>

        <hr/>
      </div>


    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import RowTwoColumns from "@/components/common/RowTwoColumns"


export default {
  name: "LanguageSkills",
  components: {RowTwoColumns},
  computed: {
    ...mapState('userinfo', ['langData']),
    ...mapState('languageskills', ['languageCertificationTypes']),
    ...mapGetters('languageskills', ['ALL_LANGUAGES', 'ALL_LANGUAGES_LEVEL']),
  },
  methods: {
    getLanguageName(lang_id) {
      let lang = this.ALL_LANGUAGES.find(i => i.id == lang_id)
      if (!lang) {
        return ''
      }
      return lang.native_name
    },
  }
}

</script>
<style scoped>
</style>
