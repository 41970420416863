<template>
  <div>
    <div class="my-research-works mt-4" v-if="articles.length">
      <InfoResearchWorkModal/>


      <div class="row mt-4">

        <div class="col-md-3">
          <label for="sort">Сортировка</label>
          <select class="form-control form-select" id="sort" @input="sortResearchWork">
            <option :value="0">Сортировать по</option>
            <option :value="1">По дате</option>
            <option :value="2">По названию</option>

          </select>
        </div>
        <div class="col-md-3">
          <label for="research_work_type">Фильтр по разделам</label>
          <select class="form-control form-select" id="research_work_type" @input="selectResearchWork">
            <option v-for="(item, index) in [{id: 0, name: 'Все'}, ...researchWorkType]" :value="item.id"
                    :key="index">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="sub_research_work_type">Фильтр по подразделам</label>
          <select class="form-control form-select" id="sub_research_work_type" @input="selectSubResearchWork">
            <option v-for="(item, index) in [{id: 0, name: 'Все'}, ...getSubResearchWorkType]"
                    :value="item.id"
                    :key="index">{{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="page_sort">Элементов на странице</label>
          <select class="form-control form-select" id="page_sort" @input="selectPageSize">
            <option v-for="(item, index) in pageSizes" :value="item"
                    :key="index">{{ item }}
            </option>
          </select>
        </div>

      </div>


      <div class="mt-4">
        <nav class="d-flex justify-content-center" aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"
                                                                                 aria-hidden="true"></i></a>
            </li>
            <li class="page-item" v-for="item in pageCount" :key="item">
              <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
            </li>
            <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right"
                                                                                   aria-hidden="true"></i></a>
            </li>
          </ul>
        </nav>
      </div>

      <div class="row" v-for="(item, index) in paginated" :key="index">

        <div class="card">
          <div class="card-body row">
            <div class="col-11">
              <div class="card-title mb-2">
                <strong>
                  {{ item.book_name }}
                </strong>
              </div>
              <div class="mb-1">Publisher: {{ item.publishing_house }}</div>
              <p class="card-text">
                <span class="bg-primary text-light rounded-3 px-2 py-1 me-2">
                  {{ getSubResearchWork(item.sub_research_work) }}
                </span>
                <span v-if="item?.year" class="bg-secondary text-light rounded-3 px-2 py-1 me-2">
                  {{ item.year }}
                </span>
                <span class="me-2">DOI: {{ item.doi }}</span>
                <span class="me-2">ISSN: {{ item.issn }}</span>
              </p>
              <p class="card-text">{{ item.co_authors }}</p>
            </div>
            <div class="col-1">
              <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                      data-bs-target="#infoResearchWork" @click="setResearchWorkEditIndex(index)"
                      v-if="current == 1">
                <i class="fa fa-info" aria-hidden="true"></i></button>
              <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                      data-bs-target="#infoResearchWork"
                      @click="setResearchWorkEditIndex(indexStart + index)"
                      v-if="current > 1">
                <i class="fa fa-info" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>

      </div>

      <div>
        <nav class="d-flex justify-content-center" aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"
                                                                                 aria-hidden="true"></i></a>
            </li>
            <li class="page-item" v-for="item in pageCount" :key="item">
              <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
            </li>
            <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right"
                                                                                   aria-hidden="true"></i></a>
            </li>
          </ul>
        </nav>
      </div>

    </div>

    <div class="mt-5 text-center" v-else>Здесь пусто</div>
  </div>
</template>


<script>

  import {mapState, mapMutations} from "vuex";
  import InfoResearchWorkModal from '@/components/vacancy-resume-info/research-work/InfoResearchWorkModal.vue'

  export default {
    name: 'ResearchWorks',
    components: {
      InfoResearchWorkModal
    },
    data() {
      return {
        current: 1,
        pageSize: 5,
        researchWorkId: 0,
        subResearchWorkId: 0,
        sortType: 0,
        pageSizes: [5, 10, 25, 50, 100]
      }
    },
    computed: {
      ...mapState('userinfo', ['articles']),
      ...mapState('researchwork', ['researchWorkType', 'subResearchWorkType']),
      indexStart() {
        return (this.current - 1) * this.pageSize;
      },
      indexEnd() {
        return this.indexStart + this.pageSize;
      },
      paginated() {
        let researchWorks = this.articles

        if (this.researchWorkId > 0) {
          researchWorks = researchWorks.filter(item => item.research_work_id == this.researchWorkId)
        }
        if (this.subResearchWorkId > 0) {
          researchWorks = researchWorks.filter(item => item.sub_research_work == this.subResearchWorkId)
        }

        if (this.sortType > 0) {
          if (this.sortType == 1) {
            researchWorks.sort((a, b) => {
              if (a.year > b.year) {
                return 1;
              }
              if (a.year < b.year) {
                return -1;
              }
              return 0;
            });
          } else if (this.sortType == 2) {
            researchWorks.sort((a, b) => {
              if (a.book_name > b.book_name) {
                return 1;
              }
              if (a.book_name < b.book_name) {
                return -1;
              }
              return 0;
            });
          }


        }
        return researchWorks.slice(this.indexStart, this.indexEnd);
      },
      pageCount() {
        let pages = []
        let researchWorks = this.articles
        researchWorks = this.researchWorkId > 0 ? researchWorks.filter(item => item.research_work_id == this.researchWorkId) : researchWorks

        for (let i = 1; i <= Math.ceil(researchWorks.length / this.pageSize); i++) {
          pages.push(i)
        }
        return pages
      },
      getSubResearchWorkType() {
        return this.researchWorkId > 0 ? this.subResearchWorkType.filter(i => i.parent_id == this.researchWorkId) : this.subResearchWorkType
      }
    },
    methods: {
      ...mapMutations('researchwork', ["UPDATE_RESEARCH_WORK_TYPE", "UPDATE_SUB_RESEARCH_WORK_TYPE", "UPDATE_RESEARCH_WORK_DATA", "SET_RESEARCH_WORK_EDIT_INDEX", "SET_RESEARCH_WORK_DELETE_ID"]),
      openResearchWorkDeleteModal(deleteResearchWorkId) {
        this.SET_RESEARCH_WORK_DELETE_ID(deleteResearchWorkId)
      },
      setResearchWorkEditIndex(idx) {
        this.SET_RESEARCH_WORK_EDIT_INDEX(idx)

      },
      prev() {
        if (this.current > 1) {
          this.current--;
        }
      },
      next() {
        if (this.current < this.articles.length / this.pageSize) {
          this.current++;
        }
      },
      movePageNumber(page) {
        this.current = page
      },
      selectResearchWork(e) {
        this.researchWorkId = e.target.value
      },
      selectSubResearchWork(e) {
        this.subResearchWorkId = e.target.value
      },
      sortResearchWork(e) {
        this.sortType = e.target.value
      },
      selectPageSize(e) {
        this.current = 1
        this.pageSize = e.target.value
      },
      getSubResearchWork(id) {
        let subResearchWorkTypeId = this.subResearchWorkType.find(i => i.id == id)
        return subResearchWorkTypeId ? subResearchWorkTypeId.name : ''
      }

    }
  }

</script>

<style scoped>

</style>