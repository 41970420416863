<template>

    <div class="education-form mt-4">
        <h4 class="text-center">Образование</h4>

        <div v-if="educationData.length">

            <hr>
            <div v-for="(educationInfo, index) in educationData" :key="index">

                <RowTwoColumns name="Учебное заведение" :value="educationInfo.name"/>
                <RowTwoColumns name="Адрес" :value="educationInfo.address"/>
                <RowTwoColumns name="Факультет" :value="educationInfo.faculty"/>
                <RowTwoColumns name="Специализация" :value="educationInfo.specialization"/>
                <RowTwoColumns name="Год поступления" :value="educationInfo.start_year"/>
                <RowTwoColumns name="Год окончания" :value="educationInfo.end_year"/>
                <RowTwoColumns name="Язык обучения" :value="getLanguageName(educationInfo.lang_id)"/>

                <div class="mt-3" v-if="1 == educationInfo.nostrification">
                    Имеется нострификация диплома
                </div>

                <hr>
            </div>

        </div>


    </div>

</template>


<script>
    import {mapGetters, mapState} from "vuex";
    import RowTwoColumns from "@/components/common/RowTwoColumns"

    export default {
        name: "EducationTab",
        components: {RowTwoColumns},
        computed: {
            ...mapState('userinfo', ['educationData']),
            ...mapGetters('education', ['allLanguages']),
        },
        methods: {
            getLanguageName(lang_id) {
                let lang = this.allLanguages.find(i => i.id == lang_id)
                if (!lang) {
                    return ''
                }
                return lang.native_name
            }
        }
    }

</script>
<style scoped>
</style>

