<template>

    <div class="job-form mt-4">
        <h4 class="text-center">Опыт работы</h4>

        <div v-if="jobData.length">
            <hr>
            <div v-for="(jobInfo, index) in jobData" :key="index">

                <RowTwoColumns name="Организация" :value="jobInfo.name"/>
                <RowTwoColumns name="Адрес" :value="jobInfo.address"/>
                <RowTwoColumns name="Сфера деятельности" :value="jobInfo.field_of_activity"/>
                <RowTwoColumns name="Начало работы" :value="convertTimestampToDate(jobInfo.start_year)"/>
                <RowTwoColumns name="Окончание работы"
                               :value="(jobInfo.working_now_status === 0)?convertTimestampToDate(jobInfo.end_year):'По настоящее время'"/>
                <RowTwoColumns name="Должность" :value="jobInfo.title"/>
                <RowTwoColumns name="Функциональные обязанности" :value="jobInfo.description"/>
                <hr>

            </div>
        </div>

    </div>

</template>


<script>
    import {mapActions, mapState} from "vuex";
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
    import RowTwoColumns from "@/components/common/RowTwoColumns"


    export default {
        name: "Job",
        components: {RowTwoColumns},

        data() {
            return {
                visa_status: 0,
            }
        },
        computed: {
            ...mapState('userinfo', ['jobData']),
        },
        methods: {
            convertTimestampToDate,
        }
    }

</script>

<style scoped>
</style>

