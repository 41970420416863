<template>
  <div class="mt-4">
    <h4 class="text-center">Научная степень</h4>

    <div class="mt-4">
      {{scienceDegree?.name}} <span v-if="scienceDegree?.child_name">({{scienceDegree?.child_name}})</span>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex"

  export default {
    name: "ScienceDegree",
    computed: {
      ...mapState('userinfo', ['scienceDegree']),
    },
  }

</script>

<style scoped>

</style>